@import "src/styles/02-tools/index";

.header-options {
    margin-right: 9.85vw;
    position: absolute;
    right: 0;

    ul {
        @include flexbox();
        @include align-items(center);
        @include justify-content(right);
        list-style: none;
    }

    li {
        a {
            font-family: var(--jost-bold-font-family);
            font-style: normal;
            font-weight: 700;
            font-size: calculateRem(16px);
            line-height: calculateRem(23px);
            @include flexbox();
            @include align-items(center);
            color: var(--dark-blue-color);
            margin: 0 calculateRem(25px);
            text-decoration: none;
            text-shadow: 0px calculateRem(3px) calculateRem(4px) var(--gray-2-color);
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

}