@import "src/styles/02-tools/index";

.introduction {
    position: relative;
    &__card {
        margin-left: calculateRem(51px);
        margin-right: calculateRem(51px);
        margin-top: calculateRem(30.5px);
        background: linear-gradient(123.74deg, var(--red-color) 29.02%, var(--white-color) 94.38%);
        background-blend-mode: lighten;
        mix-blend-mode: multiply;
        box-shadow: 0px calculateRem(4px) calculateRem(45px) rgba(0, 0, 0, 0.25);
        border-radius: calculateRem(50px) calculateRem(5px);
        min-width: calculateRem(313px);
        min-height: calculateRem(317px);
        padding-bottom: calculateRem(25px);

        @include mq-small-mobile {
            margin-left: calculateRem(16px);
            margin-right: calculateRem(16px);
            min-width: unset;
        }

        @include mq-tablet {
            margin-top: calculateRem(46px);
            max-width: unset;
            margin-left: calculateRem(51px);
            margin-right: calculateRem(51px);
            background: linear-gradient(105.51deg, var(--red-color), var(--white-color) 117.65%);
            border-radius: calculateRem(100px) calculateRem(10px);
            padding-bottom: calculateRem(83px);
        }

        @include mq-desktop {
            margin-left: calculateRem(100.5px);
            margin-right: calculateRem(81.5px);
            margin-top: calculateRem(81px);
            background: linear-gradient(102.2deg, var(--red-color) 5.37%, var(--white-color) 91.51%);
            border-radius: calculateRem(200px) calculateRem(20px);
            padding-bottom: calculateRem(188px);
        }

        h2 {
            font-family: var(--jost-bold-font-family);
            font-size: calculateRem(42px);
            line-height: calculateRem(40px);
            padding-top: calculateRem(38px);
            margin: 0;
            @include flexbox();
            @include align-items(center);
            text-align: center;
            color: var(--white-color);

            @include mq-tablet {
                font-size: calculateRem(72px);
                line-height: calculateRem(80px);
                padding-top: calculateRem(74px);
                max-width: calculateRem(648px);
                margin: 0 auto;
            }

            @include mq-desktop {
                font-weight: 700;
                font-size: calculateRem(82px);
                line-height: calculateRem(100px);
                max-width: calculateRem(764px);
                text-align: left;
                margin: calculateRem(56px) auto calculateRem(0px) calculateRem(120.5px);
            }
        }

        p {
            font-family: var(--jost-medium-font-family);
            font-size: calculateRem(18px);
            line-height: calculateRem(26px);
            text-align: center;
            margin: calculateRem(25px) calculateRem(8px);
            color: var(--white-color);

            @include mq-tablet {
                font-size: calculateRem(36px);
                line-height: calculateRem(52px);
                margin: calculateRem(29px) calculateRem(16px) calculateRem(49px);
            }

            @include mq-desktop {
                font-size: calculateRem(36px);
                line-height: calculateRem(52px);
                text-align: left;
                margin: calculateRem(16px) auto calculateRem(49px) calculateRem(104.71px);
                max-width: calculateRem(740px);
                text-shadow: 0px calculateRem(3px) calculateRem(4px) var(--gray-color);
            }
        }

        a {
            appearance: button;
            text-decoration: none;
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(center);
            align-items: center;
            font-family: var(--jost-bold-font-family);
            font-size: calculateRem(22px);
            font-weight: 700;
            color: var(--white-color);
            padding: calculateRem(5px) calculateRem(50px);
            gap: calculateRem(48px);
            min-width: calculateRem(276.38px);
            height: calculateRem(32px);
            margin: 0 auto;
            border-radius: calculateRem(12px);
            background: linear-gradient(350.99deg, var(--green-color) 6.19%, var(--green-2-color) 82.72%);
            box-shadow: 0px calculateRem(4px) calculateRem(45px) rgba(0, 0, 0, 0.35);
            border: none;
            width: max-content;

            @include mq-small-mobile {
                min-width: unset;
            }

            @include mq-tablet {
                height: calculateRem(62px);
                font-size: calculateRem(36px);
                line-height: calculateRem(52px);
            }

            @include mq-desktop {
                font-size: calculateRem(36px);
                line-height: calculateRem(52px);
                width: calculateRem(357px);
                height: calculateRem(62px);
                margin-left: calculateRem(105.6px);
                background: linear-gradient(350.99deg, var(--green-color) 6.19%, var(--green-2-color) 82.72%);
                box-shadow: 0px calculateRem(4px) calculateRem(45px) rgba(0, 0, 0, 0.35);
                border-radius: calculateRem(14px);
            }
        }

    }

    &__server-image {
        display: none;

        @include mq-desktop {
            display: block;
            position: absolute;
            width: calculateRem(288.5px);
            height: calculateRem(590.51px);
            right: calculateRem(50.4px);
            top: calculateRem(400px);
        }

        @media only screen and (min-width: 1200px) {
            position: absolute;
            width: calculateRem(288.5px);
            height: calculateRem(590.51px);
            right: calculateRem(50.4px);
            top: calculateRem(220px);
        }

        @media only screen and (min-width: 1342px) {
            position: absolute;
            width: calculateRem(388.5px);
            height: calculateRem(590.51px);
            right: calculateRem(50.4px);
            top: calculateRem(142px);
        }

    }
}