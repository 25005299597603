.mobile {
    @include mq-min-width-tablet {
        display: none !important;
    }
}

.tablet {
    display: none !important;
    @include mq-tablet {
        display: block !important;
    }
}

.desktop {
    display: none !important;
    @include mq-desktop {
        display: block !important;
    }
}

.mobile-tablet {
    display: block !important;
    @include mq-desktop {
        display: none !important;
    }
}