/** Text colors */
.white-color {
    color: var(--white-color) !important;
}

.black-color {
    color: var(--black-color) !important; 
}

.light-gray-color {
    color: var(--light-gray-color);
}

.dark-gray-color {
    color: var(--dark-gray-color);
}

.error-color {
    color: var(--error-color) !important;
}

.success-color {
    color: green !important;
}

/* Backgrounds */
.auth-bg-color {
    background-color: var(--page-background);
}