@import "src/styles/02-tools/index";

.wrapper-image-technology {
    @include flex(1 1 50%);
    flex: 1 1 50%;
    display: grid;
    place-items: center;
    place-content: center;
    margin: 1rem 0;

    @include mq-tablet {
        @include flex(1 1 33%);
        margin: 2rem 0;
    }

    @include mq-desktop {
        @include flex(1 1 25%);
        margin: 2rem 0;
    }

    &:first-of-type {
        @include mq-tablet {
            order: 1;
        }

        @include mq-desktop {
            order: 1;
        }
    }

    &:nth-of-type(2) {
        @include mq-tablet {
            order: 2;
        }

        @include mq-desktop {
            order: 6;
        }
        
    }

    &:nth-of-type(3) {
        @include mq-tablet {
            order: 4;
        }
        @include mq-desktop {
            order: 5;
        }
    }

    &:nth-of-type(4) {
        @include mq-tablet {
            order: 8;
        }

        @include mq-desktop {
            order: 2;
        }
    }

    &:nth-of-type(5) {
        @include mq-tablet {
            order: 3;
        }  
        
        @include mq-desktop {
            order: 3;
        }
    }

    &:nth-of-type(6) {
        @include mq-tablet {
            order: 5;
        }  
        
        @include mq-desktop {
            order: 7;
        }   
    }

    &:nth-of-type(7) {
        @include mq-tablet {
            order: 7;
        }  

        @include mq-desktop {
            order: 8;
        } 
    }

    &:nth-of-type(8) {
        @include mq-tablet {
            order: 6;
        }  

        @include mq-desktop {
            order: 4;
        }
    }

    img {
        @include mq-tablet {
            height: 150%;
        }

        @include mq-desktop {
            height: 170%;
        }  
    }
}