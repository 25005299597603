@import "src/styles/02-tools/index";

.testimonial-card {
    padding: calculateRem(20px);
    max-width: calculateRem(323px);
    min-width: calculateRem(323px);
    min-height: calculateRem(320.91px);
    max-height: calculateRem(320.91px);
    background: var(--white-color);
    box-shadow: 0px calculateRem(4px) calculateRem(45px) rgba(0, 0, 0, 0.25);
    border-radius: calculateRem(25px);
    margin-left: calculateRem(26px);

    @include mq-tablet {
        margin-bottom: calculateRem(11px);
    }

    @include mq-desktop {
        margin-bottom: calculateRem(34.09px);
        max-width: calculateRem(373px);
        min-width: calculateRem(373px);
    }

    &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &__header {
        @include flexbox();
        @include align-items(center);

        img {
            width: calculateRem(64px);
            height: calculateRem(64px);
            object-fit: contain;
            object-position: center;
            border-radius: 50%;
            box-shadow: 0 0 calculateRem(3px) calculateRem(1px) var(--gray-4-color);
            padding: 0 0.25rem;
        }
    }

    &__text {
        margin-left: 1rem;

        h4 {
            font-family: var(--noto-sans-bold-font-family);
            margin: 0;
            font-size: calculateRem(20px);
            line-height: calculateRem(27px);
        }

        h5 {
            font-family: var(--noto-sans-regular-font-family);
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
        }

        span {
            font-family: var(--noto-sans-regular-font-family);
            font-size: 0.8rem;
            margin: 0;
        }
    }

    p {
        font-family: var(--jost-medium-font-family);
        font-size: calculateRem(16px);
        line-height: calculateRem(23px);
        color: var(--gray-3-color);
        text-align: center;
    }
}