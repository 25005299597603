@import "src/styles/02-tools/index";

.fact-card {

    width: calculateRem(150px);
    height: calculateRem(250px);
    background: var(--white-color);
    box-shadow: 0px calculateRem(4px) calculateRem(25px) var(--light-gray-2-color);
    border-radius: calculateRem(45px);
    text-align: center;
    margin-bottom: calculateRem(30px);
    position: relative;

    @include mq-tablet {
        width: calculateRem(220px);
        height: calculateRem(400px);
    }

    @include mq-desktop {
        width: calculateRem(300px);
        height: calculateRem(400px);
    }

    h3 {
        font-family: var(--jost-bold-font-family);
        color: var(--dark-blue-color);
        font-style: normal;
        font-weight: 700;
        font-size: calculateRem(40px);
        line-height: calculateRem(58px);
        padding-top: calculateRem(13.3px);
        margin: 0;

        @include mq-min-width-tablet {
            font-size: calculateRem(72px);
            line-height: calculateRem(104px);
        }
    }

    p {
        font-family: var(--jost-regular-font-family);
        font-size: calculateRem(20px);
        line-height: calculateRem(29px);
        text-align: center;
        color: var(--black-color);
        margin-top: calculateRem(2.7px);
        margin-bottom: 0;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        @include mq-tablet {
            max-width: 75%;
            margin-top: calculateRem(10px);
            font-size: calculateRem(30px);
            line-height: calculateRem(43px);
        }

        @include mq-desktop {
            max-width: 70%;
            margin-top: calculateRem(10px);
            font-size: calculateRem(30px);
            line-height: calculateRem(43px);
        }
    }

    img {
        width: calculateRem(40px);
        height: calculateRem(40px);
        position: absolute;
        bottom: calculateRem(27px);
        left: 50%;
        translate: -50%;
        object-fit: contain;

        @include mq-tablet {
            width: calculateRem(60px);
            height: calculateRem(60px);
            bottom: calculateRem(53px);
        }

        @include mq-desktop {
            width: calculateRem(60px);
            height: calculateRem(60px);
            bottom: calculateRem(60px);
        }
    }
}

.fact-card-wrapper {
   
    &:first-of-type {
        @include flex(1 1 100%);
        @include flexbox();
        @include justify-content(center);

        @include mq-min-width-tablet {
            flex: unset;
            display: unset;
        }
    }
}