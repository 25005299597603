.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.m-1 {
    margin: 0.25rem;
}

.m1-left {
    margin-left: 0.25rem;
}

.m-1-right {
    margin-right: 0.25rem;
}

.m-2 {
    margin: 0.5rem;
}

.m2-left {
    margin-left: 0.5rem;
}

.m-2-right {
    margin-right: 0.5rem;
}

.m-3 {
    margin: 0.75rem;
}

.m3-left {
    margin-left: 0.75rem;
}

.m-3-right {
    margin-right: 0.75rem;
}

.m-4 {
    margin: 1rem;
}

.m-4-left {
    margin-left: 1rem;
}

.m-4-right {
    margin-right: 1rem;
}
