@import "src/styles/02-tools/index";

.testimonials {
    margin-top: calculateRem(104px);

    @include mq-min-width-tablet {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
    }

    &__title {
        margin: 0;
        font-family: var(--jost-bold-font-family);
        font-weight: 700;
        font-size: calculateRem(28px);
        line-height: calculateRem(40px);
        text-align: center;
        color: var(--red-4-color);

        @include mq-tablet {
            font-size: calculateRem(50px);
            line-height: calculateRem(72px);
        }
    }

    &__cards {
        position: relative;
        z-index: 2;
        padding-top: calculateRem(64.5px);
        @include flexbox();
        @include flex-wrap(nowrap);
        overflow-x: auto;
        overflow-y: hidden;
        padding-right: calculateRem(30px);
        padding-bottom: calculateRem(161px);

        @include mq-min-width-tablet {
            @include justify-content(center);
            @include flex-wrap(wrap);
            padding-top: calculateRem(51px);
            padding-bottom: 2.625rem;
        }

        @include mq-desktop {
            padding-left: calculateRem(132px);
            padding-right: calculateRem(132px);
            padding-bottom: 2.625rem;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    button {
        display: none;
        border: none;
        background: var(--red-3-color);
        box-shadow: 0px calculateRem(4px) calculateRem(4px) rgba(0, 0, 0, 0.25);
        border-radius: calculateRem(10px);
        font-family: var(--jost-bold-font-family);
        text-align: center;
        color: var(--white-color);
        cursor: pointer;

        @include mq-min-width-tablet {
            display: block;
            font-size: calculateRem(30px);
            line-height: calculateRem(43px);
        }

        @include mq-tablet {
            width: calculateRem(197px);
            height: calculateRem(43px);
            position: relative;
            bottom: 1rem;
        }

        @include mq-desktop {
            width: calculateRem(197px);
            height: calculateRem(53px);
            position: relative;
            bottom: 1rem;
        }
    }
}