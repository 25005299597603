@mixin mq-small-mobile {
  @media only screen and (max-width: $breakpoint-small-device) {
    @content;
  }
}


@mixin mq-mobile {
  @media only screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mq-tablet {
  @media only screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin mq-min-width-tablet {
  @media only screen and (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mq-desktop {
  @media only screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin mq-desktop-large {
  @media only screen and (min-width: $breakpoint-desktop-large) {
    @content;
  }
}
