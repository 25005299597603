
// --- FONTS ---
@font-face {
  font-family: "Jost-Regular";
  src: url('/assets/fonts/Jost-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Jost-Medium";
  src: url('/assets/fonts/Jost-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Jost-Bold";
  src: url('/assets/fonts/Jost-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url('/assets/fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url('/assets/fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}