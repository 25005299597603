@import "src/styles/02-tools/index";

.whatsapp {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    gap: calculateRem(10px);
    width: calculateRem(300px);
    height: calculateRem(60px);
    padding: calculateRem(5px) calculateRem(50px);
    background: linear-gradient(350.99deg, var(--green-color) 6.19%, var(--green-2-color) 82.72%);
    box-shadow: 0px calculateRem(4px) calculateRem(45px) rgba(0, 0, 0, 0.35);
    border-radius: calculateRem(10px);
    font-family: var(--jost-bold-font-family);
    font-size: calculateRem(30px);
    line-height: calculateRem(43px);
    white-space: nowrap;
    text-decoration: none;
    color: var(--white-color);
    margin: 0 auto;
    z-index: 1;
    position: relative;

    @include mq-min-width-tablet {
        font-size: calculateRem(36px);
        line-height: calculateRem(49px);
    }

    @include mq-tablet {
        width: calculateRem(450px);
        height: calculateRem(80px);
        margin-top: calculateRem(80px);
    }

    @include mq-desktop {
        margin-top: calculateRem(330px);
        width: calculateRem(552px);
        height: calculateRem(104px);
    }

    img {
        @include mq-tablet {
            width: calculateRem(70px);
            height: calculateRem(70px);
        }

        @include mq-desktop {
            width: calculateRem(70px);
            height: calculateRem(70px);
        }
    }
}