@import "src/styles/02-tools/index";

.service-card {
    padding: calculateRem(20px);
    min-width: calculateRem(260px);
    max-width: calculateRem(260px);
    background: var(--white-color);
    box-shadow: 0px calculateRem(4px) calculateRem(20px) rgba(0, 0, 0, 0.25);
    border-radius: calculateRem(45px);
    margin-left: calculateRem(30px);

    @include mq-tablet {
        min-width: calculateRem(320px);
        max-width: calculateRem(320px);
        margin-top: calculateRem(46px);
    }

    @include mq-desktop {
        margin-top: calculateRem(46px);
        min-width: calculateRem(384px);
        max-width: calculateRem(384px);
    }

    h4 {
        font-family: var(--noto-sans-bold-font-family);
        font-size: calculateRem(18px);
        line-height: calculateRem(25px);
        color: var(--black-color);
        margin-top: calculateRem(24px);
        margin-bottom: 0;
        text-align: center;

        @include mq-min-width-tablet {
            font-size: calculateRem(32px);
            line-height: calculateRem(44px);
        }

        @include mq-desktop {
            color: var(--red-3-color);
        }
    }

    p {
        font-family: var(--jost-regular-font-family);
        margin-top: calculateRem(24px);
        font-size: calculateRem(14px);
        line-height: calculateRem(20px);
        text-align: center;
        max-width: calculateRem(260px);
        margin: calculateRem(24px) auto calculateRem(20px) auto;

        @include mq-tablet {
            max-width: calculateRem(280px);
            font-size: calculateRem(24px);
            line-height: calculateRem(35px);
        }

        @include mq-desktop {
            max-width: calculateRem(280px);
            font-size: calculateRem(21px);
            line-height: calculateRem(29px);
        }
    }

    img {
        text-align: center;
        min-width: 100%;
        max-height: calculateRem(128px);
        object-fit: contain;
        margin-top: calculateRem(20px);
    }

    &:first-of-type {
        order: 5;

        @include mq-tablet {
            order: unset;
        }

        @include mq-desktop {
            order: 1;
        }
    }

    &:nth-of-type(2) {
        order: 3;

        @include mq-tablet {
            order: unset;
        }

        @include mq-desktop {
            order: 2;
        }
    }

    &:nth-of-type(3) {
        order: 6;

        @include mq-tablet {
            order: unset;
        }

        @include mq-desktop {
            order: 4;
        }
    }

    &:nth-of-type(4) {
        order: 4;

        @include mq-tablet {
            order: unset;
        }

        @include mq-desktop {
            order: 5;
        }
    }

    &:nth-of-type(5) {
        order: 1;

        @include mq-tablet {
            order: unset;
        }

        @include mq-desktop {
            order: 3;
        }
    }

    &:nth-of-type(6) {
        order: 2;

        @include mq-tablet {
            order: unset;
        }

        @include mq-desktop {
            order: 6;
        }
    }

}