$breakpoint-small-device: 405px;
$breakpoint-mobile: 640px;
$breakpoint-tablet: 940px;
$breakpoint-desktop-large: 1600px;

$base: 16px;

:root {
  // white colors
  --white-color: #fff;
  // black colors
  --black-color: #000;
  --red-color: #F11B25;
  --red-2-color: #C41C23;
  --red-3-color: #ED1C24;
  --red-4-color: #F25835;
  --green-color: #42C455;
  --green-2-color: #98E0A1;
  --gray-color: #615e5e;
  --gray-2-color: #979090;
  --gray-3-color: #00000080;
  --gray-4-color: #D6D6D6;
  --light-gray-color: #d3d3d3;
  --light-gray-2-color: #D1D1D1;
  --blue-color: #5855FF;
  --blue-2-color: #2622FF;
  --dark-blue-color: #024873;
  // Fonts
  // --- Jost
  --jost-regular-font-family: 'Jost-Regular', sans-serif;
  --jost-medium-font-family: 'Jost-Medium', sans-serif;
  --jost-bold-font-family: 'Jost-Bold', sans-serif;
  // --- NotoSans
  --noto-sans-regular-font-family: 'NotoSans-Regular', sans-serif;
  --noto-sans-bold-font-family: 'NotoSans-Bold', sans-serif;
  // paddings and margins
  --padding-page: 3rem 2rem;
}