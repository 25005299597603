@import "src/styles/02-tools/index";

.facts {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-around);
    margin: calculateRem(43px) calculateRem(32px) 0;

    @include mq-tablet {
        margin: calculateRem(185px) calculateRem(30px);
    }

    @include mq-desktop {
        @include flex-wrap(wrap);
        margin: calculateRem(160px) calculateRem(166px);
    }
}