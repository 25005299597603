* {
    box-sizing: border-box;
}


*, html {

    scroll-behavior: smooth !important;
}

body {
    font-family: 'Jost-Regular', sans-serif;
    margin: 0;
    background-color: var(--white-color);
    font-size: $base;

     header, main {
        max-width: calculateRem(1512px);
        margin: 0 auto;
    }

    main {
        @include mq-desktop {
            padding-top: 4rem;
        }
    }
}

.overflow-hidden {
    overflow: hidden;
}