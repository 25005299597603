@import "src/styles/02-tools/index";

.hamburger-btn {
  width: calculateRem(66px);
  height: calculateRem(16px);
  position: fixed;
  right: calculateRem(16px);
  top: calculateRem(24px);
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  scale: 0.4;
  cursor: pointer;
  z-index: 11;
  border: none;
  background-color: transparent;
  padding: 3rem 0;

  @include mq-tablet {
    top: calculateRem(63px);
    right: calculateRem(70px);
  }

  span {
    display: block;
    position: absolute;
    height: calculateRem(5px);
    width: 100%;
    background: var(--black-color);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: right center;
      -moz-transform-origin: right center;
      -o-transform-origin: right center;
      transform-origin: right center;
    }

    &:nth-child(2) {
      top: 18px;
      -webkit-transform-origin: right center;
      -moz-transform-origin: right center;
      -o-transform-origin: right center;
      transform-origin: right center;
    }

    &:nth-child(3) {
      top: 36px;
      -webkit-transform-origin: right center;
      -moz-transform-origin: right center;
      -o-transform-origin: right center;
      transform-origin: right center;
    }

  }

  &.open {
    width: calculateRem(60px);

    span {
      &:nth-child(1) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: -3px;
        left: -8px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 39px;
        left: -8px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-color: transparent;
      height: calculateRem(110px);
      width: calculateRem(110px);
      border-radius: 50%;
      inset: calculateRem(-33px) calculateRem(-23px);
      z-index: -1;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 8rem;
    inset: calculateRem(-39px) calculateRem(-30px);
    border-radius: 50%;
    padding: calculateRem(6px);
    background: linear-gradient(319.9deg, #ED1C24 6.37%, rgba(0, 0, 0, 0) 94.51%),linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    height: calculateRem(110px);
    width: calculateRem(110px);
  }

  &::after {
    content: "";
    position: absolute;
    background-color: var(--white-color);
    height: calculateRem(110px);
    width: calculateRem(110px);
    border-radius: 50%;
    inset: calculateRem(-33px) calculateRem(-23px);
    z-index: -1;
  }
}