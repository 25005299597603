@import "src/styles/02-tools/index";

.hamburger-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: var(--red-2-color);
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);

    ul {
        list-style: none;
        position: absolute;
        top: calculateRem(141px);
        text-align: center;
        max-width: calculateRem(121px);
        margin: 0 auto;
        padding: 0;
    }

    li {
        margin-bottom: 3rem;
    }

    a {
        text-decoration: none;
        font-family: var(--jost-medium-font-family);
        font-size: calculateRem(22px);
        line-height: calculateRem(40px);
        text-align: center;
        letter-spacing: calculateRem(-0.408px);
        color: var(--white-color);
    }

}