@import "src/styles/02-tools/index";

.header {   
    background-color: var(--white-color);
    position: relative;
    width: 100%;

    @include mq-desktop {
        @include flexbox();
        @include align-items(flex-end);
        position: fixed;
        top: 0;
        left: 50%;
        translate: -50%;
        z-index: 4;
        padding-bottom: calculateRem(31.49px);
        height: calculateRem(113px);
    }
    
    &__logo {
        width: calculateRem(140px);
        height: calculateRem(40.5px);
        margin-left: calculateRem(24px);
        margin-top: calculateRem(38px);

        @include mq-tablet {
            width: calculateRem(242px);
            height: calculateRem(70px);
            margin-left: calculateRem(48px);
            margin-top: calculateRem(60px);
        }

        @include mq-desktop {
            width: calculateRem(172.86px);
            height: calculateRem(50px);
            margin-left: calculateRem(120px);
            margin-top: calculateRem(54px);
        }
    }

}