@import "src/styles/02-tools/index";

.services {
    margin-top: calculateRem(53px);

    @include mq-tablet {
        margin-top: calculateRem(196px);
    }

    @include mq-desktop {
        margin-top: calculateRem(169px);
    }

    &__title {
        margin: 0;
        text-align: center;
        font-family: var(--jost-bold-font-family);
        font-size: calculateRem(22px);
        line-height: calculateRem(32px);
        color: var(--dark-blue-color);

        @include mq-tablet {
            font-size: calculateRem(42px);
            line-height: calculateRem(61px);
        }

        @include mq-desktop {
            font-size: calculateRem(36px);
            line-height: calculateRem(52px);
        }
    }

    &__subtitle {
        font-family: var(--jost-bold-font-family);
        font-size: calculateRem(24px);
        line-height: calculateRem(35px);
        text-align: center;
        margin: 0.25rem auto;
        color: var(--dark-blue-color);
        text-shadow: 0px calculateRem(4px) calculateRem(25px) rgba(0, 0, 0, 0.25);

        @include mq-tablet {
            font-size: calculateRem(42px);
            line-height: calculateRem(61px);
            margin: calculateRem(22px) auto;
            color: var(--red-3-color);
        }

        @include mq-desktop {
            font-size: calculateRem(50px);
            line-height: calculateRem(72px);
            color: var(--red-3-color);
        }

        span {
            color: var(--red-3-color);
        }
    }

    &__cards {
        position: relative;
        z-index: 2;
        padding-top: calculateRem(23px);
        padding-bottom: calculateRem(28px);
        @include flexbox();
        @include flex-wrap(nowrap);
        overflow-x: auto;
        overflow-y: hidden;
        padding-right: calculateRem(30px);

        @include mq-min-width-tablet {
            @include justify-content(center);
            @include flex-wrap(wrap);
            padding-top: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}