.modal {
    overflow: hidden;
}

.MuiModal-root.MuiDialog-root {
    > .MuiDialog-container {
        > .MuiPaper-root {
            > .MuiDialogContent-root {
                > div {
                    > div {
                        > div {
                            > .MuiFormControl-root {
                                > .MuiInput-root {
                                    &::after {
                                        border-color: initial !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
