@import "src/styles/02-tools/index";

.footer {
    z-index: 2;
    position: relative;
    width: 100%;
    background: var(--red-3-color);
    margin-top: calculateRem(78px);
    height: 100%;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);

    @include mq-tablet {
        margin-top: calculateRem(140px);
        @include flex-direction(column);
        @include align-items(center);
    }

    @include mq-desktop {
        margin-top: calculateRem(187px);
        padding-left: calculateRem(118px);
        padding-right: calculateRem(114px);
        padding-top: calculateRem(30px);
        padding-bottom: calculateRem(18px);
        @include flex-direction(row);
        @include align-items(unset);
    }

    section {
        text-align: center;
        @include mq-desktop {
            text-align: unset;
        }

        &:first-of-type {
            @include mq-desktop {
                margin-right: 6.87vw;
            }
        }


        &.links {
            @include flex(1 1 100%);
            margin-bottom: 2rem;

            @include mq-min-width-tablet {
                @include flex(unset);
                margin-bottom: 0;
            }
    
            @include mq-desktop {
                margin-right: calculateRem(81.5px);
            }
        }

        img {
            margin-top: calculateRem(24px);
            filter: invert(33%);
        }

        p {
            font-family: var(--jost-regular-font-family);
            font-weight: 300;
            font-size: 1rem;
            line-height: 150%;
            max-width: calculateRem(304px);
            white-space: normal;
            color: var(--gray-4-color);

            @include mq-desktop {
                margin: calculateRem(16px) 0 auto;
            }
        }

        h4 {
            font-family: var(--jost-medium-font-family);
            font-weight: 500;
            font-size: calculateRem(20px);
            line-height: calculateRem(29px);
            color: var(--white-color);
            margin-top: calculateRem(10px);
            text-align: center;

            @include mq-desktop {
                text-align: unset;
            }
        }

        a {
            font-family: var(--jost-regular-font-family);
            font-weight: 300;
            color: var(--white-color);
            font-size: calculateRem(16px);
            line-height: calculateRem(23px);
            margin-top: calculateRem(20px);
            text-decoration: none;
            display: block;
            text-align: center;
            @include mq-desktop {
                text-align: unset;
            }
        }

        input {
            box-shadow: calculateRem(6px) calculateRem(6px) calculateRem(12px) rgba(41, 41, 41, 0.09);
            border-radius: calculateRem(10px);
            width: calculateRem(230px);
            height: calculateRem(43px);
            border: none;
            padding: calculateRem(10px);
            padding-left: calculateRem(23px);
            background-color: var(--blue-color);
            caret-color: var(--white-color);

            &::placeholder {
                color: var(--white-color);
                font-family: var(--jost-regular-font-family);
                font-weight: 100;
                font-size: 1rem;
                line-height: calculateRem(23px);
            }
        }

        button {
            font-family: var(--jost-regular-font-family);
            font-weight: 400;
            font-size: calculateRem(18px);
            line-height: calculateRem(26px);
            width: calculateRem(122px);
            height: calculateRem(46px);
            padding: calculateRem(10px);
            background: var(--white-color);
            border-radius: calculateRem(10px);
            color: var(--blue-2-color);
            border: none;
            margin-left: 1rem;

            @include mq-desktop {
                margin-left: 0;
                margin-top: calculateRem(42px);
            }
        }
    }

    &__wrapper-links {
        @include flexbox();
        @include justify-content(space-around);
        @include flex-wrap(wrap);
        width: 100%;
    }

    &__RRSS {
        margin-bottom: calculateRem(19.56px);
        @include flexbox();
        @include justify-content(center);

        @include mq-desktop {
            @include justify-content(flex-start);
        }

        img {
            width: calculateRem(128px);
            height: auto;
            object-fit: contain;
            margin-right: calculateRem(11px);
        }
    }
}