@import "src/styles/02-tools/index";

.technologies {
    margin-top: calculateRem(65px);

    @include mq-tablet {
        margin-top: calculateRem(311px);
    }

    &__title {
        margin: 0;
        text-align: center;
        font-family: var(--jost-bold-font-family);
        font-size: calculateRem(22px);
        line-height: calculateRem(32px);
        color: var(--dark-blue-color);

        @include mq-tablet {
            font-size: calculateRem(42px);
            line-height: calculateRem(61px);
        }

        @include mq-desktop {
            font-size: calculateRem(36px);
            line-height: calculateRem(52px);
        }
    }

    &__subtitle {
        font-family: var(--jost-bold-font-family);
        font-size: calculateRem(24px);
        line-height: calculateRem(35px);
        text-align: center;
        margin: 0.25rem 11.594vw calculateRem(24px);
        color: var(--red-3-color);

        @include mq-tablet {
            font-size: calculateRem(42px);
            line-height: calculateRem(61px);
            max-width: calculateRem(672px);
            margin: calculateRem(4px) auto calculateRem(22px) auto;
        }

        @include mq-desktop {
            font-size: calculateRem(50px);
            line-height: calculateRem(72px);
        }

        br {
            display: none;
            @include mq-tablet {
                display: block;
            }
        }
    }

    &__images {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-around);
    }

}